import React, { useState } from "react"
import PropTypes from "prop-types"
import parse from "react-html-parser"

const Collapse = props => {
  const [toggle, setToggle] = useState(false)
  const { children, title, className, onClick, id } = props

  const handleClickHide = () => {
    setToggle(!toggle)
  }

  const renderContent = () => {
    if (typeof children === "string") {
      return parse(children)
    }

    return children
  }

  const handleClick = () => {
    if (typeof onClick === "function" && !toggle) {
      onClick(id)
    }
  }

  return (
    <button onClick={handleClick} className={`Collapse ${className}`}>
      <div className="Collapse-head">
        <button className="Collapse-btn" onClick={handleClickHide}>
          {title}
        </button>
      </div>
      <div className={`Collapse-body ${toggle ? "" : "hide"}`}>
        {renderContent()}
      </div>
    </button>
  )
}

Collapse.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default Collapse
