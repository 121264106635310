import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import TitleBlock from "../components/TitleBlock"
import Collapse from "../components/Collapse"
import { useAxiosClient } from "../service/useAxiosClient"
import TitlePage from "../components/TitlePage"

export default function FAQ({ location }) {
  const [faq, setFaq] = useState([])

  const [{ data: dataFaq }] = useAxiosClient({
    url: `/faqs/web/?nitems=100`,
    method: "GET",
  })

  const [, triggerCount] = useAxiosClient(
    {
      method: "GET",
    },
    { manual: true }
  )

  useEffect(() => {
    if (dataFaq && Array.isArray(dataFaq)) {
      setFaq(dataFaq)
    }
  }, [dataFaq])

  const path = [{ label: "Inicio", to: "/" }, { label: "Preguntas frecuentes" }]

  const handleCount = async id => {
    if (!id) return

    await triggerCount({
      url: `/faqs/${id}/visits/`,
    })
  }

  const rendertQuestions = () => {
    return (
      faq &&
      faq.map(item => (
        <Collapse
          className="FrequentQuestion-collapse"
          id={item?.pk}
          onClick={handleCount}
          title={item?.question}
        >
          {item?.answer}
        </Collapse>
      ))
    )
  }

  return (
    <Layout>
      <TitlePage title="Preguntas frecuentes" url={location.pathname} />
      <Hero image="/imgs/quienes-somos.jpg" text="Preguntas frecuentes" />
      <div className="container950">
        <div className="FrequentQuestion">
          <Breadcrumb path={path} />
          <TitleBlock title="FAQ" icon="fas fa-pen-square" />
          <p className="FrequentQuestion-description">
            A continuacón dispone de las preguntas frecuentes. Cualquier otra
            consulta, puede comunicarse con nosotros mediante
            informacion@efpa.es:
          </p>
          <div className="FrequentQuestion-content">{rendertQuestions()}</div>
        </div>
      </div>
    </Layout>
  )
}
